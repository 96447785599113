<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">历思承办案件入选福建省高级人民法院2015年十大典型案件</div>
      <p class="time">2016.12.09</p>
      <p class="space_content">
        在2016年福建省高级人民法院公布的2015年十大典型案件中，福建历思司法鉴定所受厦门市中级人民法院委托进行文书形成时间等司法鉴定，鉴定意见成为案件关键证据，有力支持了司法审判工作。</p>
      <!--            <div class="col8" style="margin:2rem auto">-->
      <!--                <img class="col8" v-lazy="require('../../assets/news/ls/2-1.png')">-->
      <!--                <p class="center_">曾建晟作为新一届厦门司法鉴定协会监事长发表讲话</p>-->
      <!--            </div>-->
      <!--            <p class="space_content">厦门市司法鉴定协会是由厦门市司法鉴定机构和司法鉴定人及从事司法鉴定工作的相关人员组成的具有法人资格的、地方性、专业性、非营利性的社会组织。</p>-->
      <div class="space_content ">
            <span class="redText" style="margin: 10px">
              案件：申请执行人中国厦门国际经济技术合作公司与被执行人厦门某商贸有限公司、黄某委托合同纠纷案
            </span>
      </div>
      <p class="space_content titleText">【案情】</p>
      <p class="space_content">
        申请执行人厦门国际经济技术合作公司根据已经生效的（2012）厦民初字第1078号调解书向厦门市中级人民法院申请执行，要求被执行人厦门某商贸有限公司、黄某支付各项债务合计人民币7600多万元。
        在执行过程中，厦门市中院依法查封了被执行人名下位于厦门市湖滨西路10号的房产，经委托评估后三次公开拍卖均未成交。申请执行人向厦门市中院申请以房产作价抵债。厦门市中院依法裁定将该房产以最后一次拍卖流拍价格抵偿给申请执行人，并公告责令被执行人自行限期搬迁出该房产。</p>
      <p class="space_content">
        随后，案外人谢某向厦门市中院提出执行异议，要求停止对上述查封房产的强制搬迁，并提交了其租赁该房产10年的租赁合同，租金一次性抵销被执行人拖欠其借款本息合计695万元。厦门市中院依法委托福建历思司法鉴定所对案外人谢某提供的租赁合同的签署时间进行司法鉴定。
        <span style="font-weight: 600;font-size: 16px">福建历思司法鉴定所</span>
        于2015年10月出具《文书司法鉴定意见书》，
        确认“厦门某商贸有限公司与谢某于2011年4月25日签订的《商场租赁合同》中，落款处的签名笔迹及其在2011年4月25日签署的《房屋交接确认书》，落款处的签名笔迹不符合其落款时间书写形成，
        符合文件倒签特征，二者与2015年9月14日签署的《授权委托书》中手写笔迹存在同期形成的可能。</p>
      <p class="space_content">
        <span style="font-weight: 600;font-size: 16px"> 据此，厦门市中院于11月11日作出处罚决定书，</span>
        认定被执行人拒绝搬迁，并与案外人谢某串通，通过倒签合同的方式，编造虚假租赁合同，以案外人谢某的名义向厦门市中院提出第三人异议，请求停止对上述查封房产的强制搬迁执行，
        严重干扰人民法院依法强制执行活动，拒不履行生效法律文书确定的义务，情节恶劣，决定拘留被执行人厦门某商贸有限公司的法定代表人黄某和案外人谢某15日，并各处罚款人民币5万元；
        对厦门某商贸有限公司罚款人民币20万元。在厦门市中院作出拘留决定书后，厦门某商贸有限公司的法定代表人黄某对其违法行为具结悔过，并缴纳了5万元的罚款，且积极配合厦门市中院移交厦门市湖滨西路10号房产。
      </p>
      <p class="space_content titleText">【评述】</p>
      <p class="space_content">近年来，在人民法院执行生效裁判处置已被查封的被执行人不动产的过程中，有时出现众多的“长期租约”，其中不乏以“虚假租约”的形式在执行标的物上恶意设置权利负担，以逃避履行义务，
        严重损害债权人的合法权益，妨碍了人民法院对执行标的物的司法处分。本案的处理，对打击被执行人恶意对抗人民法院依法执行行为有明显的警示意义，对于保护债权人的合法权益和维护法律尊严具有积极作用。</p>
      <p class="space_content titleText">【相关链接】</p>
      <p class="space_content" style="word-wrap:break-word">
        <a style="text-align: left; word-break: break-all;
        word-wrap: break-word;
"
           href=" https://mp.weixin.qq.com/s?__biz=MzI5MTA5OTgxOA==&mid=402020198&idx=1&sn=8871cfc8ff28f1a0bc2a464ffc353f9c&mpshare=1&scene=1&srcid=1209ouReHHSYnaVtLE7GZ6wD&pass_ticket=XsVF24FMp%2F27zwEiOHyHRuWBJESZQfAlPK5%2B%2F1jiB0q8vIuMExffRxFGXV7Hg0gH#rd">
          https://mp.weixin.qq.com/s?__biz=MzI5MTA5OTgxOA==&mid=402020198&idx=1&sn=8871cfc8ff28f1a0bc2a464ffc353f9c&mpshare=1&scene=1&srcid=1209ouReHHSYnaVtLE7GZ6wD&pass_ticket=XsVF24FMp%2F27zwEiOHyHRuWBJESZQfAlPK5%2B%2F1jiB0q8vIuMExffRxFGXV7Hg0gH#rd
        </a>
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/3'"><p class="ellipsis1">
            省创新研究院中科育成中心领导莅临历思调研</p></router-link>

        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/5'"><p class="ellipsis1">
            历思入选环境保护部环境损害鉴定推荐机构名录</p></router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
